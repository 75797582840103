/* Tabs */
.portfolio-container{
   padding: 10px;
   border-radius: 5px;
   min-height: 100vh;
}

.customTabs .customTabs-item, .customTabs-item-active{
   padding: 0 !important;
   min-width: 0 !important;
   margin-right: 20px !important;
   text-transform: inherit !important;
   font-size: 14px !important;
   font-weight: 400 !important;
   outline-width: 0 !important;
}


.customTabs-item-active{
    color: var(--main-color) !important;
}

/* Cards */
.custom-card{
    
}
.custom-card .custom-card-image{

}
.custom-card .custom-card-title{

}
.custom-card .custom-card-caption{
   font-weight: 550 !important;
}

.MuiCardActions-spacing{
    padding: 0 !important;
}

/* Dialog */

.project-dialog{
    overflow: hidden !important;
}
.project-dialog .project-dialog-image{
   overflow: hidden !important;
   /* height: 100px; */
}
.project-dialog .project-dialog-description{
    overflow: hidden;
    margin-top: 10px;
}
.project-dialog .project-dialog-actions{
   display: flex;
   align-items: center;
   justify-content: center;

}

.project-dialog .project-dialog-icon{
   color: darkslategray;
   margin: 0 12px;
   cursor: pointer;
}

.project-dialog .project-dialog-icon:hover{
   color: var(--main-color);
}

.project-dialog .project-dialog-icon .MuiSvgIcon-root{
   font-size: 30px;
}

.link-block{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content:center;
   margin-right: 10px;
}