.image-gallery{
   width: 100%;
   height: auto;
}

.image-gallery .image-gallery-slide img{
    width: 100%;
    height: 100%;
    max-height: 50vh !important;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img{
    width: 100vw;
    height: 100vh;
    max-height: 100vh !important;
}