
.contact-container{
    padding: 10px;
    border-radius: 5px;
}

.contactInfo-item{
    font-size: 14px !important;
    color: #787878;
}
.contactInfo-item > span{
    color: #000;
    font-weight: 500;
}

.contactInfo-socialsContainer{
    margin-top: 25px;
}

.contactInfo-social > a > span> .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 10px;
}
