.custom-btn {
  background-color: var(--main-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: 0 !important;
  border-radius: 50px !important;
  padding: 1px 12px !important;
  line-height: 35px !important;
  min-width: 100px !important;
  /* min-height: 25px; */
  outline: none;
}

.custom-btn .btn-icon-container {
  color: #000;
  background: #fff;
  border-radius: 50px !important;
  height: 27px !important;
  width: 27px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 29px !important;
}

.custom-btn .btn-icon-container > .MuiSvgIcon-root {
  font-size: 16px;
}

.custom-btn .btn-text {
  font-size: 14px;
  text-transform: none;
  text-align: center;
  width: 100%;
  margin: 0 5px !important;
  font-weight: 600;
}

.custom-btn:hover {
  background-color: #FEB100 !important;
}

.custom-btn a{
   text-decoration: none;
   color: #000;
}