.dark-mode{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
} 
@media  screen and (max-width: 650px){
    .dark-mode{
        width: 40%;
    } 
}
.night{
    font-size: 2rem;
    margin-left: 0.5rem; 
    color: white;
    padding-bottom: 0.7rem;
}
.day{
    color:#FFBE00;
    font-size: 2.5rem;
    margin-left: 0.5rem;
    padding-bottom: 0.7rem;
}
.toggle{
    
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFBE00;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #3d3d18;
}
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
