:root {
  --main-color: #ffc500;
}

::-webkit-scrollbar{
  width: 0.5rem;
}
::-webkit-scrollbar-thumb{
  background-color: #f3ad3d;
}
::-webkit-scrollbar-track{
  background: white;
}

/* Margins and paddings */
.top-60 {
  margin-top: 60px !important;
}

.top-30{
  margin-top: 30px !important;
}

.bottom-40{
  margin-bottom: 40px !important;
}

.p-30{
  padding: 30px !important;
}

.pt-45{
  padding-top: 50px;
}

.pb-60{
  padding-bottom: 60px !important;
}

@media (max-width: 991px) {
  .top-60 {
    margin-top: 30px;
  }
}

.container-shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.3);
}

.main-content{
  /* background-color: #fff; */
  min-height: 100vh;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  margin-bottom: 30px;
}

.section{
  padding-left: 35px;
  padding-right: 35px;
}

.whitebg{
  background-color: #fff;
}

.section-title{
  position: relative;
  display: inline-block;
  padding: 0 3px;
}

.section-title .section-title-text {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  margin: 0;
}

.section-title > span{
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: var(--main-color);
}