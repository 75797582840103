.header {
  margin-bottom: 30px !important;
  background: #fff;
  padding: 0px 22px 0px 0;
  border-radius: 6px;
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
}

.header-link {
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  /* line-height: 73px; */
  font-size: 14px;
  display: block;
}

.header .header-link:hover {
  color: var(--main-color) !important;
  text-transform: uppercase;
  font-size: 14px;
}

.header .header-link-active {
  font-weight: bold;
  color: var(--main-color) !important;
  text-transform: uppercase;
  font-size: 14px;
}

.header .header-home-navlink {
  padding: 0;
}

.header .header-home {
  background-color: var(--main-color);
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.header .header-home:hover {
  background-color: #FEB100 !important;

}

.header .header-left {
  margin-right: auto;
}

.header .header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .header-right > a > span > .MuiSvgIcon-root {
  font-size: 16px;
  margin-right: 12px;
}

@media only screen and (max-width: 990px){
  .header-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
  }
  .header-left .header-link {
    width : 100%;
    padding: 15px;
    font-size: 16px;
  }
  .header-left .header-link-active{
    width : 100%;
    padding: 15px;
    font-size: 16px;
  }

  .header-right{
    margin-bottom: 15px;
  }
}